import { REFERENCE, DEVICE } from "../constants";
import Request from "../../../services/Request";

export default {
  setDevice(deviceId: string): any {
    return (dispatch: any) => {
      dispatch({
        payload: {
          deviceId: deviceId
        },
        type: DEVICE.SET
      });
    };
  },
  getReferences(): any {
    const url = `/referentiels`;
    return (dispatch: any) => {
      return Request.get(url).then((response: any) => {
        dispatch({
          payload: {
            devices: response.data
          },
          type: REFERENCE.SET
        });
      });
    };
  }
};
