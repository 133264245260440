import React from "react";
import moment from "moment";

export default function CustomAxisTick(props: any) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {moment(payload.value).format("LT")}
      </text>
    </g>
  );
}
