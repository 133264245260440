import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MeasurementActions } from "../store/redux/actions";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend, ResponsiveContainer } from "recharts";
import CustomAxisTick from "./utils/CustomAxisTick";
import "../styles/HistogramChart.scss";
import moment from "moment";

function HistogramChart(props: any) {
  const { getMeasurements, periods, deviceId } = props;
  useEffect(() => {
    if (deviceId) {
      getMeasurements(deviceId, 30);
    }
  }, [deviceId]);

  const formatLabel = (date: any) => {
    return moment(date).format("L");
  };

  return (
    <div className="histogram">
      <div className="histogram-title">Evolution temporelle (24h)</div>
      <ResponsiveContainer>
        <LineChart data={periods} margin={{ top: 20, right: 30, left: 0, bottom: 0 }} className="histogram-chart">
          <XAxis
            label={{ value: "Date", position: "insideBottomRight", offset: 0 }}
            dataKey="receviedAt"
            angle={30}
            dx={20}
            height={60}
            tick={<CustomAxisTick />}
            interval={8}
          />
          <YAxis label={{ position: "insideLeft", offset: 0 }} type="number" domain={["dataMin - 1", "dataMax + 1"]} />
          <Tooltip labelFormatter={formatLabel} />
          <CartesianGrid stroke="#ccc" strokeDasharray="6 6" vertical={false} />
          <Legend verticalAlign="bottom" height={36} />
          <Line name="Temperature" type="monotone" dataKey="content.temperature" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const mapStateToProps = (props: any) => {
  return { periods: props.measurement.allMesurement.periods, deviceId: props.references.deviceId };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(MeasurementActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistogramChart);
