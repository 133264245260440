import { REFERENCE, DEVICE } from "../constants";
import { ReferenceActionTypes, IReference } from "../types";

const initialState: IReference = {
    devices: [],
    deviceId: "",
};

const referenceReducers = (
  state = initialState,
  action: ReferenceActionTypes,
): IReference => {
  switch (action.type) {
    case REFERENCE.SET:
        return { ...state, devices: action.payload.devices};
    case DEVICE.SET:
        return { ...state, deviceId: action.payload.deviceId};
    default:
      return state;
  }
};

export default referenceReducers;
