export enum MEASUREMENT {
  SET = "MEASUREMENT.SET",
  LATEST = "MEASUREMENT.LATEST",
}

export enum REFERENCE {
  SET = "REFERENCE.SET",
}

export enum DEVICE {
  SET = "DEVICE.SET",
}
