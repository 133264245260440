import axios from "axios";
import { config } from "../config";

class Request {
  private base: string;

  constructor() {
    this.base = config.apiUrl as string;
  }

  public async get(endpoint: string) {
    return this.sendRequest(endpoint);
  }
  private sendRequest(endpoint: string) {
    const httpInstance = this.getHttpInstance();
    return httpInstance.get(endpoint);
  }
  private getHttpInstance() {
    const { base } = this;
    const httpInstance = axios.create({
      baseURL: `${base}`,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    httpInstance.interceptors.response.use(
      async (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      },
    );
    return httpInstance;
  }
}

export default new Request();
