import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { ReferenceActions, MeasurementActions } from "../store/redux/actions";
import { IReference, IDevice } from "../store/redux/types";
import { bindActionCreators } from "redux";
import Select from "react-select";
import useInterval from "../custom/useInterval";

interface IChooseDevice {
  devices: IDevice[];
  getReferences: () => void;
  getLatestMeasurement: (deviceId: string) => any;
  getMeasurements: (deviceId: string, days: number) => void;
  setDevice: (deviceId: string) => void;
  deviceId: string;
}
function ChooseDevice(props: IChooseDevice) {
  const { getReferences, getMeasurements, getLatestMeasurement, setDevice, devices, deviceId } = props;
  const [selectedOption, setSelectedOption] = useState();

  useInterval(() => {
    getMeasurements(deviceId, 30);
    getLatestMeasurement(deviceId);
  }, 900000); //900000

  useEffect(() => {
    getReferences();
  }, []);

  const handleChange = (changeValue: any) => {
    setSelectedOption(changeValue);
    setDevice(changeValue.device);
  };

  return (
    <>
      <Form>
        <Form.Group controlId="form-device">
          <Form.Label>Sélectionner un site</Form.Label>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={devices}
            getOptionValue={(option) => `${option.device}`}
            getOptionLabel={(option) => `${option.site}`}
          />
        </Form.Group>
      </Form>
    </>
  );
}

const mapStateToProps = (props: { references: IReference }) => {
  return { devices: props.references.devices, deviceId: props.references.deviceId };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReferenceActions, ...MeasurementActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDevice);
