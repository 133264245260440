import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { IMeasurementDetail } from "../store/redux/types";
import "../styles/SimpleMap.scss";

function SimpleMap(props: { latest: IMeasurementDetail }) {
  const { latest } = props;
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(13);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (latest.localisation) {
      setCenter(latest.localisation.split(",").map(Number));
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [latest]);

  return (
    <>
      {display && (
        <Map center={center} zoom={zoom}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={center}>
            <Popup>{latest.site}</Popup>
          </Marker>
        </Map>
      )}
    </>
  );
}

const mapStateToProps = (props: any) => {
  return {
    latest: props.measurement.latest,
  };
};

export default connect(mapStateToProps)(SimpleMap);
