import { MEASUREMENT } from "../constants";
import { MeasurementActionTypes, IMeasurement } from "../types";

const initialState: IMeasurement = {
  latest: {
    equipmentId: "",
    gatewayId: "",
    localisation: "",
    site: "",
    periods: []
  },
  allMesurement: {
    equipmentId: "",
    gatewayId: "",
    localisation: "",
    site: "",
    periods: []
  }
};

const measurementReducers = (
  state = initialState,
  action: MeasurementActionTypes
): IMeasurement => {
  switch (action.type) {
    case MEASUREMENT.SET:
      return { ...state, allMesurement: action.payload.allMesurement };
      case MEASUREMENT.LATEST:
        return { ...state, latest: action.payload.latest };
    default:
      return state;
  }
};

export default measurementReducers;
