import { combineReducers } from "redux";
import measurementReducers from "./measurement";
import referenceReducers from "./reference";

const rootReducer = () =>
  combineReducers({
    references: referenceReducers,
    measurement: measurementReducers,
  });

export default rootReducer;
