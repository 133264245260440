import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MeasurementActions } from "../store/redux/actions";
import moment from "moment";
import "../styles/LatestChart.scss";
import thermometer from "../assets/thermometer.svg";
import { Row, Col } from "react-bootstrap";
import SimpleMap from "./SimpleMap";

function LatestChart(props: any) {
  const { getLatestMeasurement, latest, deviceId } = props;
  useEffect(() => {
    if (deviceId) {
      getLatestMeasurement(deviceId);
    }
  }, [deviceId]);

  const batteryStatus = (content: { batteryStatus: boolean }) => {
    return content ? (content.batteryStatus ? "ON" : "OFF") : "none";
  };
  const getTemperature = (content: { temperature: number }) => {
    return content ? `${content.temperature}` : "none";
  };

  const getHumidity = (content: { humidity: number }) => {
    return content ? `${content.humidity}%` : "none";
  };

  return (
    <Row>
      {latest.periods[0] && (
        <Col md={4} className="measure">
          <Row className="measure-info">
            <Col className="measure-info__date">
              <span>{moment(latest.periods[0].receviedAt).format("L")}</span>
            </Col>
            <Col className="measure-info__battery">
              <span>Batterie: {batteryStatus(latest.periods[0].content)}</span>
              <span>Humidité: {getHumidity(latest.periods[0].content)}</span>
            </Col>
          </Row>
          <Row className="measure-temperature">
            <Col className="measure-temperature__icon">
              <img src={thermometer} alt="thermometer" height={150} />
            </Col>
            <Col className="measure-temperature__text">
              {getTemperature(latest.periods[0].content)} <span>°C</span>
            </Col>
          </Row>
        </Col>
      )}
      <Col md={4} className="measure-detail measure-detail__text">
        <pre>{JSON.stringify(latest, null, 2)}</pre>
      </Col>
      <Col md={4} className="measure-detail measure-detail__map">
        <SimpleMap />
      </Col>
    </Row>
  );
}

const mapStateToProps = (props: any) => {
  return {
    latest: props.measurement.latest,
    deviceId: props.references.deviceId,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(MeasurementActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestChart);
