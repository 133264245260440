import React from "react";
import "./App.scss";
import LatestChart from "./components/LatestChart";
import HistogramChart from "./components/HistogramChart";
import { Container, Row, Col } from "react-bootstrap";
import ChooseDevice from "./components/ChooseDevice";

const App = () => {
  return (
    <div className="App">
      <Container className="container-app">
        <Row className="choose-device">
          <Col>
            <ChooseDevice />
          </Col>
        </Row>
        <Row className="charts">
          <Col md={12} className="charts__latest">
            <LatestChart />
          </Col>
        </Row>
        <Row className="charts">
          <Col md={12} className="charts__histogram">
            <HistogramChart />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
