import Request from "../../../services/Request";
import { MEASUREMENT } from "../constants";

export default {
  getLatestMeasurement(deviceId: string): any {
    const url = `/device/${deviceId}/period/latest`;
    return (dispatch: any) => {
      return Request.get(url).then((response: any) => {
        dispatch({
          payload: {
            latest: response.data
          },
          type: MEASUREMENT.LATEST
        });
      });
    };
  },
  getMeasurements(deviceId: string, days: number): any {
    const url = `/device/${deviceId}/period/${days}`;
    return (dispatch: any) => {
      return Request.get(url).then((response: any) => {
        dispatch({
          payload: {
            allMesurement: response.data
          },
          type: MEASUREMENT.SET
        });
      });
    };
  }
};
